
<template>
  <div>
    <Filters
      :perPage="perPage"
      :perPageOptions="perPageOptions"
      :benefits="benefits"
      @change-per-page="changePerPage"
      @get-detail-transactions="getDetailTransactions"
    />
    <div>
      <TableTransactionsDetail
        :transactionsdetail="detailTransactionsData"
        :tableColumns="tableColumns"
        :sortBy="sortBy"
        :isSortDirDesc="isSortDirDesc"
        :isLoadingDetailTransactions="isLoadingDetailTransactions"
        fixed
        responsive
        small
        style="overflow:auto"
        @set-tab-index="setTabIndex"
      />
      <PaginationTable
        v-if="detailTransactions.length > perPage && !isLoadingDetailTransactions"
        :currentPage="currentPage"
        :totalRows="filteredDetailTransactions.length"
        :perPage="perPage"
        @change-page="changePage"
      />
    </div>
  </div>
</template>

<script>
import { formatDateOnly } from "@/helpers/helpers";
import { mapActions, mapMutations, mapState,mapGetters } from "vuex";
import Filters from '@/modules/fivesClub/components/reports/transactionsDetails/Filters'
import TableTransactionsDetail from '@/modules/fivesClub/components/reports/transactionsDetails/TableDetailTransactions'
import PaginationTable from '@/modules/fivesClub/components/reports/PaginationTable'

export default {
  components:{
    TableTransactionsDetail,
    PaginationTable,
    Filters,
  },
  props: {
    benefits: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      transactionsdetailData: [],
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 15, 20, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      tableColumns: [
        { key: "benefit", label: "Beneficio" },
        { key: "ownerName", label: "Propietario" },

        { key: "invoice", label: "Orden" },
        { key: "quantity", label: "Cantidad" },
        { key: "operationdate", label: "Fecha de consumo" },
        { key: "notes", label: "Notas" },
        { key: "statusTransactions", label: "estado" },

        // { key: "createdate", label: "Creacion" },
        // { key: "userName", label: "Creado por" },
        // { key: "canceldate", label: "Cancelación" },
        // { key: "userCancelName", label: "Cancelado por" },
        // { key: "cancelNotes", label: "Notas de Cancelación" },
      ],
      isLoadingDetailTransactions: false,
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState('fivesClubReports', ['detailTransactions']),
    ...mapGetters('fivesClubReports',['filteredDetailTransactions']),
    detailTransactionsData(){
      if (this.filteredDetailTransactions?.length) return this.filteredDetailTransactions?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
    },
  },
  methods: {
    ...mapActions("fivesClubReports", ["fetchTransactionsDetail",]),
    ...mapMutations('fivesClubReports', ['setDetailTransactions','setRequestDetailTransactions']),
    async getDetailTransactions(payload){
      this.setRequestDetailTransactions(payload)
      this.isLoadingDetailTransactions = true
      this.setDetailTransactions([])
      const detailTransactions = await this.fetchTransactionsDetail(payload)
      this.setDetailTransactions(detailTransactions)
      this.isLoadingDetailTransactions = false
    },
    formatDateInfo(fecha, locale) {
      return formatDateOnly(fecha, locale);
    },
    changePage(page){
      this.currentPage = page
    },
    changePerPage(perPage){
      this.perPage = perPage
    },
    setTabIndex(index){
      this.$emit('set-tab-index', index)
    },
  },
}
</script>


<style lang="scss" >
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 9px;
  }
  .buttonInfo button {
    padding: 9px;
  }

  .buttonInfo {
    display: table-cell;
  }
}
</style>
