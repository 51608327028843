<template>
  <b-card>
    <b-tabs>
      <b-tab active>
        <template #title>
          <span class="d-none d-sm-inline">Saldos de Beneficios</span>
        </template>
        <Transactions :benefits="benefits"/>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">Consumos de Beneficios</span>
        </template>
        <TransactionsDetail :benefits="benefits"/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapActions } from "vuex"
import Transactions from '@/modules/fivesClub/components/reports/transactions/ListTransactions'
import TransactionsDetail from '@/modules/fivesClub/components/reports/transactionsDetails/ListDetailTransactions'

export default {
  async created() {
    const response = await this.fetchBenefits()
    this.benefits = response
  },
  components:{
    Transactions,
    TransactionsDetail,
  },
  data() {
    return {
      benefits: []
    }
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchBenefits']),
  }
}
</script>
