
<template>
  <div>
    <Filters
      :perPage="perPage"
      :perPageOptions="perPageOptions"
      :benefits="benefits"
      @change-per-page="changePerPage"
      @get-transactions="getTransactions"
    />
    <div>
      <TableTransactions
        :transactions="transactionsData"
        :tableColumns="tableColumns"
        :sortBy="sortBy"
        :isSortDirDesc="isSortDirDesc"
        fixed
        responsive
        small
        style="overflow:auto"
        :isLoadingTransactions="isLoadingTransactions"
        @set-tab-index="setTabIndex"
      />
      <PaginationTable
        v-if="transactions.length > perPage && !isLoadingTransactions"
        :currentPage="currentPage"
        :totalRows="filteredTransactions.length"
        :perPage="perPage"
        @change-page="changePage"
      />
    </div>
  </div>
</template>

<script>
import { formatDateOnly } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Filters from '@/modules/fivesClub/components/reports/transactions/Filters'
import TableTransactions from '@/modules/fivesClub/components/reports/transactions/TableTransactions'
import PaginationTable from '@/modules/fivesClub/components/reports/PaginationTable'

export default {
  components:{
    TableTransactions,
    PaginationTable,
    Filters,
  },
  props: {
    benefits: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 15, 20, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      tableColumns: [
        { key: "code", label: "Código", class: "text-center" },
        { key: "benefit", label: "Beneficio", class: "text-center" },
        { key: "quantity", label: "Cantidad" },
        { key: "balance", label: "Balance" },
        { key: "currency", label: "Moneda" },
        { key: "initialdate", label: "Fecha inicio" },
        { key: "enddate", label: "Fecha final" },
        { key: "internalNotes", label: "Notas" },
        { key: "statusTransactions", label: "Estatus" },
        { key: "userName", label: "Usuario" },
      ],
      isLoadingTransactions: false,
      // benefits: null
    };
  },
  // async mounted() {
  //   const response = await this.fetchBenefits()
  //   this.benefits = response
  // },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('fivesClubReports', ['transactions','requestTransactions']),
    ...mapGetters('fivesClubReports',['filteredTransactions']),
    transactionsData(){
      if (this.filteredTransactions?.length) return this.filteredTransactions?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
    },
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchBenefits']),
    ...mapActions('fivesClubReports', ['fetchTransactions']),
    ...mapMutations('fivesClubReports', ['setTransactions','setRequestTransactions' ]),
    async getTransactions(payload){
      this.isLoadingTransactions = true
      this.setTransactions([])
      this.setRequestTransactions(payload)
      const transactions = await this.fetchTransactions(payload)
      this.setTransactions(transactions)
      this.isLoadingTransactions = false
    },
    formatDateInfo(fecha, locale) {
      return formatDateOnly(fecha, locale);
    },
    changePage(page){
      this.currentPage = page
    },
    changePerPage(perPage){
      this.perPage = perPage
    },
    setTabIndex(index){
      this.$emit('set-tab-index', index)
    },
  },
};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 9px;
  }
  .buttonInfo button {
    padding: 9px;
  }

  .buttonInfo {
    display: table-cell;
  }
}
</style>
