<template>
  <div>
    <b-table
      :items="transactions"
      :fields="tableColumns"
      :tbody-tr-class="rowClass"
      primary-key="id"
      :sort-by.sync="isSortBy"
      show-empty
      empty-text="Haga una busqueda mediante el filtro"
      :sort-desc.sync="isSortDir"
      class="position-relative mb-0"
      :busy.sync="isLoadingTransactions"
    >
      <template #cell()="data">
        <span class="text-nowrap">
          {{ data.value}}
        </span>
      </template> 
      <template #empty="scope">
        <b-alert variant="warning" :show="true" class="mb-0">
          <div class="alert-body"> <feather-icon icon="InfoIcon" class="mr-50" />
            <span>{{ scope.emptyText }}</span>
          </div>
        </b-alert>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Cargando Saldo de Beneficios</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { utils } from "@/modules/fivesClub/mixins/utils"


export default {
  mixins: [utils],
  props: {
    transactions: {
      type: Array,
      required: false,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    isSortDirDesc: {
      type: Boolean,
      required: true,
    },
    isLoadingTransactions: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isSortDir: this.isSortDirDesc,
      isSortBy: this.sortBy,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState('fivesClubCatalogs', ['filter']),   
  },
};
</script>

<style lang="scss" >
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th  {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 9px;
  }
  .buttonInfo button{
    padding: 9px;
  }
  .buttonInfo {
    display: table-cell;
  }
  .isCentered{
    align-content: center;
    align-items: center;
  }
  .isSpinner{
    padding: 2rem;
  }
}
</style>
