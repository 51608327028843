<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>Filtros</h4>
      <div>
        <b>Total de Consumos: </b> <b-badge variant="success" class="mr-1"> {{detailTransactions.length}} </b-badge>
        <b-button
          type="submit"
          size="sm"
          class="btn excel-btn mr-1"
          @click="downloadExcelDetailTransactions"
          v-if="requestDetailTransactions && detailTransactions.length && !isDowloadingExcel"
        > <feather-icon icon="FileTextIcon" /> Excel
        </b-button>
        <b-spinner label="Loading..." v-if="isDowloadingExcel"/>
      </div>
    </div>

    <validation-observer v-slot="{ invalid }" ref="searchDetailTransactions">
      <b-form @submit.prevent="getTransactionsDetail">
        <b-row>
          <!-- Beneficio -->
          <b-col md="3">
            <validation-provider name="Beneficio" rules="">
              <b-form-group slot-scope="{ valid, errors }" label="Beneficio">
                <b-form-select
                  class="form-control"
                  type="text"
                  v-model="filterData.benefit"
                  :state="errors[0] ? false : valid ? true : null"
                >
                  <option :value="null">Seleccione un beneficio</option>
                  <option v-for="bnf in benefits" :key="bnf.id" :value="bnf.id">
                    {{bnf.code}}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Tipo de fechas -->
          <b-col md="3" >
            <validation-provider name="tipo fecha" rules="required">
              <b-form-group slot-scope="{ valid, errors }" label="Tipo de fecha">
                <b-form-select
                  class="form-control"
                  v-model="filterData.dateType"
                  :state="errors[0] ? false : valid ? true : null"
                >
                  <option :value="null" disabled> Seleccione una opción</option>
                  <option v-for="dates in dateOptions" :key="dates.value" :value="dates.value">{{dates.text}}</option>
                </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Rango de fechas -->
          <b-col md="3" v-if="filterData.dateType" >
            <validation-provider name="fecha inicio" rules="required">
              <b-form-group slot-scope="{ valid, errors }" label="Fecha inicio">
                <b-form-datepicker
                  class="form-control"
                  type="date"
                  v-model="filterData.dateIn"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :state="errors[0] ? false : valid ? true : null"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3" v-if="filterData.dateType" >
            <validation-provider name="fecha fin" rules="required">
              <b-form-group slot-scope="{ valid, errors }" label="Fecha fin">
                <b-form-datepicker
                  class="form-control"
                  type="date"
                  v-model="filterData.dateEnd"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :state="errors[0] ? false : valid ? true : null"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Botones -->
          <b-col>
            <b-form-group label=".">
              <div>
                <b-button
                  class="mr-1"
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="invalid"
                > Buscar
                </b-button>
                <b-button
                  class="mr-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  @click="resetFilter"
                > Limpiar
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col/>
        </b-row>
      </b-form>
    </validation-observer>

    <b-row class="mb-1" v-if="detailTransactions.length > 0">
      <b-col md="2">
        <label>Numero de registros</label>
        <v-select
          v-model="porPagina"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector"
          @input="changePerPage"
        />
      </b-col>
      <b-col md="4">
        <label>Buscar en tabla</label>
        <div class="d-flex">
          <b-form-input
            class="mr-1"
            placeholder="Buscar..."
            type="search"
            v-model="filter.queryDetailTransactions"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapMutations,mapActions  } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { currentDate, showAlertMessage } from '@/helpers/helpers'
import * as moment from 'moment'

export default {
  directives: {
    Ripple,
  },
  mixins: [utils],
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
    benefits: {
      type: Array,
      required: false
    }
  },
  components: {
    vSelect,
  },

  data() {
    return {
      porPagina: this.perPage,
      fechaActual: currentDate(),
      filterData: {
        dateIn: null,
        dateEnd: null,
        dateType: null,
        benefit: null,
      },
      isDowloadingExcel: false,
      dateOptions: [
        {value: 'operationdate', text: 'Fecha de consumo'},
        {value: 'createdate', text: 'Fecha de creación'},
        {value: 'canceldate', text: 'Fecha de cancelación'},
        {value: 'importdate', text: 'Fecha de importación'}
      ]
    };
  },
  computed: {
    ...mapState('fivesClubReports', ['filter','detailTransactions','requestDetailTransactions']),

  },
  methods: {
    ...mapActions('fivesClubReports', ['fetchExcelDetailTransactions']),
    ...mapMutations('fivesClubReports', ['setDetailTransactions','setRequestDetailTransactions']),
    changePerPage(perPage) {
      this.$emit('change-per-page', perPage);
      this.porPagina = perPage
    },
    getTransactionsDetail() {
      const isValidDates = moment(this.filterData.dateEnd).isSameOrAfter(this.filterData.dateIn, 'day')
      if(!isValidDates){
          showAlertMessage('Fechas inválidas', 'BellIcon', '¡Fecha fin no debe ser menor a fecha de inicio', 'danger', 3000, 'bottom-right')
          return
      }
      this.$emit('get-detail-transactions', this.filterData)
    },
    resetFilter(){
      this.$refs.searchDetailTransactions.reset()
      this.filterData = {
        dateIn: null,
        dateEnd: null,
        dateType: null,
        benefit: null,
      }
      this.setDetailTransactions([])
      this.setRequestDetailTransactions(null)
    },
    async downloadExcelDetailTransactions(){
      this.isDowloadingExcel = true
      const payload = {
        ...this.requestDetailTransactions,
        DownExcel: true
      }
      await this.fetchExcelDetailTransactions(payload)
      this.isDowloadingExcel = false
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}
.filters {
  padding-bottom: 2rem;
}
.paddler {
  padding-left: 1rem;
}

.filters {
  border: solid 1px #b8c2cc;
  padding: 12px 12px 13px 12px !important;
  margin: 0 0 16px 0;
}
.excel-btn{
  background-color: #1D6F42!important;
}
</style>